<script lang="ts">
  import { navigate } from "astro:transitions/client";
  // This component does not have styles intentionally
  // They were not loading properly when astro was navigating back to the page
  // Probably the component did not had tme to inject the styles
  export let href: string;
  export let isDisabled = false;
  export let classes = "";

  let clicked = false;
  let coinElement: HTMLImageElement;

  function dingOnClick(e: MouseEvent) {
    e.preventDefault();
    clicked = true;
    let audio;
    if (isDisabled) {
      audio = new Audio("/sounds/smb_breakblock.wav");
    } else {
      audio = new Audio("/coin.wav");
    }
    coinElement.onanimationend = () => {
      clicked = false;
      navigate(href);
    };
    audio.play();
  }
</script>

<a
  {href}
  class="flex flex-col justify-center items-center uppercase text-white font-mario {classes}"
  style="text-shadow: var(--mario-shadow)"
  on:click={dingOnClick}
>
  <span class="relative">
    <img
      src="/images/coin.svg"
      alt="Moneda de la suerte"
      class="w-10 h-10 mx-auto absolute top-0 left-0 right-0 opacity-0 transition-all duration-250"
      class:animation-coin-active={clicked}
      bind:this={coinElement}
    />
    {#if isDisabled}
      <img src="/109.svg" alt="question mark" class="w-16 h-16" />
    {:else}
      <img
        src="/questionmark.svg"
        alt="question mark"
        class="w-16 h-16 font-mario"
        class:bounce={clicked}
      />
    {/if}
  </span>
  <slot />
</a>
