<script lang="ts">
  import { appContext } from "@client/store";
  import Block from "./Block.svelte";

  $: me = appContext.me;
</script>

{#each $me?.pages || [] as page}
  <Block classes="animation-zoom-in" href={page.path} isDisabled={false}>
    {page.title}
  </Block>
{/each}
